<template>
    <div class="pageBox">
        <div class="mainPage">
            <div class="typebox" v-if="options.length>0">
                <div class="titleType">
                    分类
                </div>
                <div class="lineTypeBox">
                    <div :class="`lineType ${item.id==query.types?'lineActive':''}`" 
                    @click="GetDatas(item.id)"
                    v-for="(item, index) in options" 
                    :key="index">{{ item.name }}</div>
                </div>
            </div>
            <div class="typeTable">
                <div class="posi" @click="goback">案例库/更多</div>
                <div class="tabelBox">
                    <div class="tableLine" v-for="(item,index) in tabelList" :key="index.vue">
                        <div class="tableLine-left">
                            <img :src="item.cover"/>
                        </div>
                        <div class="tableLine-right">
                            <div class="tableLine-title">{{ item.title }}</div>
                            <div class="tableLine-msg">
                                <div style="margin-right: 50px;">时间：{{ item.createtime }}</div>
                                <div>来源：{{ item.sourcename }}</div>
                            </div>
                            <div class="tableLine-desc">
                                {{ item.summary }} 
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="pagination"> 
                    <el-pagination 
                    layout="total, prev, pager, next" 
                    :current-page="query.page"
                    :page-size="query.size"
                    @current-change="pageChange"
                    :total="total" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { OH_byType, OH_typeList } from '@/api/openHome.js'
export default {
    data(){
        return{
            bigType:4, //3区域, 4材质，5环境, 6现有案例
            options:[],
            tabelList:[],
            query:{
                page:1,
                size:10,
                types:null,
            },
            total:0,
        }
    },
    mounted(){
        let type = this.$route.query.type
        if(type){
            this.bigType = type
        }
        this.getOption()
        
    },
    methods:{
        getOption(){
            OH_typeList(this.bigType).then(r=>{ //材质
                this.options = r.data
                if(this.options.length>0){
                    this.GetDatas(this.options[0].id)
                }else{
                    this.GetDatas(this.bigType)
                }
                
            })
        },
        GetDatas(id){
            this.query.types = id
            this.CX(0)
        },
        pageChange(page){
            this.query.page = page
            this.CX(1)
        },
        CX(type){
            if(type == 0){
                this.query.page = 1
            }
            OH_byType(this.query).then(r=>{
                this.tabelList = r.data.list  
                this.total =  r.data.total 
            })
        },
        goback(){
            this.$router.go(-1)
        }
    }
}
</script>
<style lang="scss" scoped>
.pageBox{
    width: 100%;
    box-sizing: border-box;
}
.mainPage{
    width: 1300px;
    margin:  0 auto;
    padding: 70px 0px 50px 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .typebox{
        width: 260px;
        height: 700px;
        background-color: #e5edff;
        margin-right: 10px;
        .titleType{
            width: 100%;
            height: 80px;
            line-height: 80px;
            background: #3073FF;
            font-size: 20px;
            text-align: center;
            color: white;
        }
        .lineTypeBox{
            width: 100%;
            height: 600px;
            overflow: auto;
        }
        .lineType{
            line-height: 40px;
            padding-left: 50px;
            border-bottom: 1px solid rgb(246, 246, 246);
            box-sizing: border-box;
            cursor: pointer;
        }
        .lineType:hover{
            background-color: #BFD1F7;
            border-right: 2px solid #6D9DFF;
        }
        .lineActive{
            background-color: #BFD1F7;
            border-right: 2px solid #6D9DFF;
        }
    }
    .typeTable{
        flex: 1;
        background-color: rgb(255, 255, 255);
        padding: 20px;
        box-sizing: border-box;
        .posi{
            margin-bottom: 10px;
            color: #666;
            font-size: 14px;
            cursor: pointer;
            width: 100px;
        }
        .tabelBox{
            width: 100%;
            min-height: 500px;
        }
        .tableLine{
            margin-bottom: 20px;
            width: 100%;
            height: 140px;
            display: flex;
            justify-content: space-between;
            background-color: #F7F7F7;
            padding: 10px;
            box-sizing: border-box;
            .tableLine-left{
                width: 200px;
                height: 100%;
                background-color: #BFD1F7;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            .tableLine-right{
                margin-left: 10px;
                width: calc(100% - 210px);
                height: 100%;
                .tableLine-title{
                    font-size: 20px;
                }
                .tableLine-msg{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 12px;
                    color: #999999;
                    line-height: 30px;
                    border-bottom: 1px solid #dbdbdb;
                }
                .tableLine-desc{
                    margin-top: 5px;
                    color: #787878;
                    font-size: 14px;
                    line-height: 30px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}
.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
</style>